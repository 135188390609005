<template>
  <section :class="sectionClasses">
    <div class="flex flex-col">
      <BaseText v-bind="titleProps">
        {{ $t('holdings.assets.price_title') }}
      </BaseText>
      <BaseText size="subheadline-small">
        {{ readableValues.currentPrice }}
      </BaseText>
    </div>
    <div class="flex flex-col" v-if="is24hrVisibleOnTile">
      <BaseText v-bind="titleProps">
        {{ assetPriceTimestamp }}
      </BaseText>
      <BaseText :color="priceColor" size="body-text-x-medium">
        {{ readableValues.priceChangeDynamic.value }} ({{
          readableValues.priceChangeDynamic.percentage
        }})
      </BaseText>
    </div>
    <div class="flex flex-col">
      <BaseText v-bind="titleProps">
        {{ $t('holdings.assets.holdings_title') }}
      </BaseText>
      <BaseText size="body-text-x-medium">
        {{ readableValues.currentHolding }} /
        {{ readableValues.currentHoldingAmount }}
      </BaseText>
    </div>
    <div class="flex flex-col" v-if="is24hrVisibleOnTile">
      <BaseText v-bind="titleProps">
        {{ holdingPriceTimestamp }}
      </BaseText>
      <BaseText :color="holdingColor" size="body-text-x-medium">
        {{ readableValues.holdingChangeDynamic.value }} ({{
          readableValues.holdingChangeDynamic.percentage
        }})
      </BaseText>
    </div>
  </section>
</template>

<script>
import HoldingBaseAssetInformation from '@/modules/holdings/HoldingBaseAssetInformation';

export default {
  name: 'HoldingAssetInformation',
  extends: HoldingBaseAssetInformation,
  computed: {
    isCard() {
      return this.type === 'card';
    },
    isSmall() {
      return this.size === 'small';
    },
    isTile() {
      return this.type === 'tile';
    },
    is24hrVisibleOnTile() {
      if (this.isCard) {
        return true;
      }
      return !this.isSmall && this.isTile;
    },
    sectionClasses() {
      return {
        'asset-content': true,
        'asset-resume-card': this.isCard,
        'asset-resume-tile-large': !this.isSmall && this.isTile,
        'asset-resume-tile-small': this.isSmall && this.isTile,
      };
    },
    titleProps() {
      return {
        tag: 'h5',
        color: 'text-text-inactive',
        size: 'body-text-x-small',
      };
    },
    priceColor() {
      return `text-text-${this.readableValues.priceChangeDynamic.type}`;
    },
    holdingColor() {
      return `text-text-${this.readableValues.holdingChangeDynamic.type}`;
    },
    assetPriceTimestamp() {
      const timeframe = this.readableValues.priceChangeDynamic.timeframe;
      return this.$t(
        `market.change_timeframes.change${
          timeframe && timeframe !== 0 ? timeframe : '_1W'
        }`
      );
    },
    holdingPriceTimestamp() {
      const timeframe = this.readableValues.holdingChangeDynamic.timeframe;
      return this.$t(
        `market.change_timeframes.change${
          timeframe && timeframe !== 0 ? timeframe : '_1W'
        }`
      );
    },
  },
};
</script>

<style scoped>
.asset-resume-card {
  @apply grid gap-s8;
  grid-template-columns: 1fr 1fr;
}

.asset-resume-tile-large {
  @apply grid gap-s64 pl-s16 items-center;
  grid-template-columns: repeat(4, 1fr);
}

.asset-resume-tile-small {
  @apply grid gap-s16 pl-s16;
  grid-template-columns: 1fr 1fr;
}
</style>
