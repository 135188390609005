<template>
  <component
    v-if="data.ticker !== 'USD'"
    :is="currentBaseType"
    v-bind="baseProps"
    :data-testid="data.type"
  >
    <component v-bind="informationProps" :is="currentInformationType" />
  </component>
</template>

<script>
import BaseAssetCard from '@/components/misc/Assets/BaseAssetCard/index.vue';
import BaseAssetTile from '@/components/misc/Assets/BaseAssetTile/index.vue';

import HoldingAssetInformation from '@/modules/holdings/HoldingAssetInformation/index.vue';
import HoldingDropInformation from '@/modules/holdings/HoldingDropInformation/index.vue';
import HoldingPackInformation from '@/modules/holdings/HoldingPackInformation/index.vue';

export default {
  name: 'HoldingItem',

  components: {
    BaseAssetCard,
    BaseAssetTile,
    HoldingAssetInformation,
    HoldingDropInformation,
    HoldingPackInformation,
  },

  props: {
    data: Object,

    isLoading: Boolean,

    size: {
      type: String,
      default: 'large',
    },

    type: {
      type: String,
      default: 'tile',
    },
  },

  computed: {
    baseProps() {
      if (this.isComponentLoading) {
        return {
          isLoading: this.isComponentLoading,
          image: '',
          title: '',
          to: '',
        };
      }

      if (this.data.type === 'HoldingPack') {
        return {
          tag: 'div',
          isLoading: false,
          title: this.$t('holdings.packs.title'),
          image: this.data.image,
        };
      }

      return {
        isLoading: false,
        image: this.data.image,
        title: this.data.collection_name || this.data.title,
        subTitle: this.data.short_name,
        to: this.data.to,
      };
    },

    currentBaseType() {
      return {
        card: BaseAssetCard,
        tile: BaseAssetTile,
      }[this.type];
    },

    currentInformationType() {
      return {
        HoldingAsset: 'HoldingAssetInformation',
        HoldingDrop: 'HoldingDropInformation',
        HoldingPack: 'HoldingPackInformation',
      }[this.data.type];
    },

    informationProps() {
      if (this.isComponentLoading) {
        return {
          rawValues: {},
          readableValues: {},
          size: this.size,
          type: this.type,
        };
      }

      return {
        ...this.data,
        size: this.size,
        type: this.type,
      };
    },

    isComponentLoading() {
      return this.isLoading || this.data.isLoading;
    },
  },
};
</script>
