<template>
  <header>
    <Title
      :class="[titleClass]"
      :title="$tc('holdings.header.holdings')"
    />

    <HoldingsResume
      :isLoading="globalIsLoading"
      :holdings="getHoldings"
      :netCost="getNetCost"
      :profitLoss="getProfitLoss24Hrs"
    />
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import { Title } from '@/components/misc';
import HoldingsResume from '../HoldingsResume/';

export default {
  name: 'HoldingsHeader',

  components: {
    HoldingsResume,
    Title,
  },

  computed: {
    ...mapGetters('wallet',[
      'getNetCost',
      'getHoldings',
      'getProfitLoss24Hrs',
    ]),
    
    titleClass() {
      return !this.isMobileDevice ? 'mb-s24' : 'mb-s12';
    },
  },  
};
</script>
