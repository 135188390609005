<template>
  <aside>
    <div class="start-lg:flex hidden flex-row tabs-filters-section">
      <PrimaryTabs
        class="subheadline-small start-xl:headline-small"
        :currentTab="currentTab"
        :tabList="tabList"
        @tabChange="changeTab"
      />

      <div class="flex gap-s16">
        <HoldingsCardType v-model="card" />

        <div
          v-if="isAssetsTab"
          class="flex justify-center items-center"
        >
          <BaseText size="body-text-x-medium">
            {{$t('sort_by')}}
          </BaseText>

          <DropdownList
            class="ml-s12"
            hideArrow
            :selectedDropdown="selectedSort"
            :dropdownItems="dropdownItems"
            @onDropdownAction="onDropdownAction"
          />
        </div>
      </div>
    </div>

    <div class="start-lg:hidden flex flex-col gap-s12">
      <div class="tabs-filters-section flex">
        <PrimaryTabs
          class="subheadline-small start-xl:headline-small"
          :currentTab="currentTab"
          :tabList="tabList"
          @tabChange="changeTab"
        />
      </div>
      
      <div
        v-if="isAssetsTab"
        class="flex justify-end items-center"
      >
        <BaseText size="body-text-x-medium">
          {{$t('sort_by')}}
        </BaseText>

        <DropdownList
          class="ml-s12"
          hideArrow
          :selectedDropdown="selectedSort"
          :dropdownItems="dropdownItems"
          @onDropdownAction="onDropdownAction"
        />
      </div>
    </div>
  </aside>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { TRACKING_EVENT_TYPE } from '@/modules/holdings/events';
import { BaseText, PrimaryTabs } from '@/components/misc';
import { DropdownList } from '@/components/dropdown';
import HoldingsCardType from '@/modules/holdings/HoldingsCardType';

export default {
  name: 'HoldingsTabsAndFilters',

  components: {
    BaseText,
    DropdownList,
    HoldingsCardType,
    PrimaryTabs,
  },

  props: {
    cardType: {
      type: String,
      required: true,
    },

    selectedTab: {
      type: String,
      required: true,
    },
  },

  async mounted() {
    this.setInitialSelectedTab();

    await this.fetchTab();
    await this.dispatchEvents();
  },

  data() {
    const dropdownItems = [
      { label: this.$t('holdings.filters.value'), faIcon: 'long-arrow-alt-down', action: 'value_desc' },
      { label: this.$t('holdings.filters.value'), faIcon: 'long-arrow-alt-up', action: 'value_asc' },
      { label: this.$t('holdings.filters.price'), faIcon: 'long-arrow-alt-down', action: 'price_desc' },
      { label: this.$t('holdings.filters.price'), faIcon: 'long-arrow-alt-up', action: 'price_asc' },
      { label: this.$t('holdings.filters.volume'), action: 'volume_desc' },
    ];

    return {
      card: 'card',
      dropdownItems,
      selectedSort: dropdownItems[0],
    };
  },

  computed: {
    ...mapGetters({ pagination: 'holdings/getPagination' }),

    isAssetsTab() {
      return this.selectedTab === 'assets';
    },

    currentTab() {
      return this.tabList
        .find(item => item.type === this.selectedTab).label;
    },

    tabList() {
      return [
        {
          type: 'assets',
          label: this.$t('holdings.tabs.my_assets')
        },
        {
          type: 'watchlist',
          label: this.$t('holdings.tabs.watchlist')
        },
        {
          type: 'packs',
          label: this.$t('holdings.tabs.packs'),
        }
      ];
    },
  },

  watch: {
    card(type) {
      this.$emit('onCardUpdate', type);
    },

    selectedSort({ action }) {
      this.$emit('onSortUpdate', action);
    },

    selectedTab(tab) {
      this.$emit('onTabUpdate', tab);
    }
  },

  methods: {
    ...mapActions('holdings', [
      'fetchHoldings',
      'resetState',
    ]),
    
    setInitialSelectedTab() {
      const isRouteItemOnList = this.tabList.map(item => item.type)
        .includes(this.$route.params.type);

      const selectedTab = isRouteItemOnList
        ? this.$route.params.type
        : this.tabList[0].type;

      this.$emit('onTabUpdate', selectedTab);
    },

    async fetchTab() {
      await this.$emit('onListUpdate', true);

      try {
        await this.resetState();
        
        await this.fetchHoldings({
          type: this.selectedTab,
          sort: this.selectedSort.action,
        });
      } catch(e) {
        this.showError(e);
      } finally {
        await this.$emit('onListUpdate', false);
      }
    },

    async changeTab(newTab) {
      await this.$emit('onTabUpdate', newTab.type);
      await this.fetchTab();

      window.history.replaceState({}, null, `/holdings/${newTab.type}`);
      await this.dispatchEventTab();
    },

    async onDropdownAction(action) {
      if (this.selectedSort === action) {
        return;
      }

      this.selectedSort = action;
    },

    async dispatchEvents() {
      await this.$store.dispatch('events/track', { 
        event: 'HOLDINGS_VIEWED' 
      });
      
      await this.dispatchEventTab();
    },

    async dispatchEventTab() {
      await this.$store.dispatch('events/track', {
        event: TRACKING_EVENT_TYPE[this.selectedTab],
      });
    }
  },
};
</script>

<style scoped>
.tabs-filters-section {
  @apply justify-between border-border border-b;
}
</style>
