import Vue from 'vue';

export default Vue.component('BaseAsset', {
  props: {
    tag: {
      type: String,
      default: 'router-link'
    },
    image: String,
    to: {
      type: [String, Object],
      default: ''
    },
    isLoading: Boolean,
    title: String,
    subTitle: String,
  },
  computed: {
    link(){
      if (this.tag === 'router-link') {
        return this.to;
      }
      return null;
    }
  }
});
