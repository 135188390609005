<template>

  <SkeletonLoading class="asset-loading" v-if="isLoading" :hasCustomClass="true" />

  <component
    v-else
    :is="tag"
    :to="link"
    class="card-grid bg-background-primary">

    <SquareImage
      class="asset-image"
      :image="image"
    />

    <div>
      <BaseText class="asset-title" size="subheadline-x-small">
        {{ title }}
      </BaseText>
      <BaseText v-if="subTitle" class="asset-subtitle" size="body-text-x-large">
        {{ subTitle }}
      </BaseText>
    </div>

    <slot />

  </component>

</template>

<script>
import BaseAsset from '../BaseAsset/index.js';

import {
  BaseText,
  SquareImage,
} from '@/components/misc';

export default {
  name: 'BaseAssetCard',
  extends: BaseAsset,
  components: {
    BaseText,
    SquareImage,
  }
}
</script>

<style scoped>
.asset-image {
  grid-area: image;
}

.asset-title {
  grid-area: title;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.asset-subtitle {
  @apply mt-s8;
  grid-area: title;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 40px;
}

.asset-content {
  grid-area: content;
}

.asset-loading {
  height: 216px;
  width: 100%;
}

.card-grid {
  @apply grid border-t py-s32 px-s16 gap-y-s8 gap-x-s16;
  grid-template-rows: min-content 1fr;
  grid-template-areas: 'image title' 'image content';
  grid-template-columns: 100px 1fr;
}

.card-grid:last-of-type {
  @apply border-b;
}

@screen start-lg {
  .asset-loading,
  .card-grid {
    @apply border-border border rounded-16;
  }
}
</style>
