<template>
  <div class="flex gap-s32">

    <div class="flex flex-col gap-s8">
      <BaseText
        v-bind="titleLoadingSettings">
        {{ $t('holdings.header.holdings') }}
      </BaseText>

      <BaseText
        color="text-text-positive"
        size="subheadline-small start-lg:headline-small"
        v-bind="valueLoadingSettings">
        {{ holdings }}
      </BaseText>
    </div>

    <div class="flex flex-col gap-s8">
      <BaseText
        v-bind="titleLoadingSettings">
        {{ $t('holdings.header.net_cost') }}
      </BaseText>

      <BaseText
        size="subheadline-small start-lg:headline-small"
        v-bind="valueLoadingSettings">
        {{ netCost }}
      </BaseText>
    </div>

    <div class="flex flex-col gap-s8">
      <BaseText
        v-bind="titleLoadingSettings">
        {{ $t('holdings.header.profit_loss') }}
      </BaseText>

      <BaseText
        :color="`text-text-${profitLoss.type}`"
        size="subheadline-small start-lg:headline-small"
        v-bind="valueLoadingSettings">
        {{ profitLoss.value }} <span class="body-text-medium start-lg:subheadline-medium">({{ profitLoss.percentage }})</span>
      </BaseText>
    </div>
    
  </div>
</template>

<script>
import { BaseText } from '@/components/misc';

export default {
  name: 'HoldingsResume',
  components: {
    BaseText,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: true
    },
    holdings: String,
    netCost: String,
    profitLoss: {
      type: Object,
      validator(value){
        const properties = Object.keys(value);
        return ['value', 'type', 'percentage'].every(item => {
          return properties.find(property => item === property);
        });
      }
    },
  },
  computed: {
    titleLoadingSettings(){
      return {
        isLoading: this.isLoading,
        loadingHeight: '18px',
        loadingWidth: '135px',
      };
    },
    valueLoadingSettings(){
      return {
        isLoading: this.isLoading,
        loadingHeight: this.isMediumMobileDevice ? '20px' : '28px',
        loadingWidth: '135px',
      };
    },
    profitLossClasses(){
      const textColor = `text-text-${this.profitLoss.type}`;

      return {
        'subheadline-large': true,
        [textColor]: true,
      };
    }
  }
};
</script>

<style scoped>
</style>
