import Vue from 'vue';

import {
  BaseText,
} from '@/components/misc';

export default Vue.component('HoldingBaseAssetInformation', {
  components: {
    BaseText,
  },
  props: {
    rawValues: Object,
    readableValues: Object,
    size: {
      type: String,
      default: 'large'
    },
    type: String,
  },
  computed: {
    isCard(){
      return this.type === 'card';
    },
    isSmall(){
      return this.size === 'small';
    },
    isTile(){
      return this.type === 'tile';
    },
    titleProps(){
      return {
        tag:'h5',
        color:'text-text-inactive',
        size:'body-text-x-small'
      };
    },
    valueBigProps(){
      return {
        size: 'subheadline-small'
      };
    },
    valueProps(){
      return {
        size: 'body-text-x-medium'
      };
    },
  }
});
