<template>
  <section class="asset-content grid items-center">
    <div :class="sectionClasses">
      <div class="flex flex-col">
        <BaseText v-bind="titleProps">
          {{ $t('holdings.assets.price_title') }}
        </BaseText>

        <BaseText v-bind="valueBigProps">
          {{ currentPrice }}
        </BaseText>
      </div>

      <div class="flex flex-col">
        <BaseText v-bind="titleProps">
          {{ $t('holdings.packs.status') }}
        </BaseText>

        <BaseText v-bind="valueProps" class="capitalize">
          {{ rawValues.state }}
        </BaseText>
      </div>

      <div
        v-if="showTileButton"
        class="flex"
      >
        <ButtonV2
          @onClick="packMethod"
          :label="$t('frac_pack.open_pack_button')"
          testId="btn-OpenPack"
          size="small"
        />
      </div>
    </div>

    <div
      v-if="showCardButton"
      class="flex"
    >
      <ButtonV2
        @onClick="packMethod"
        :label="$t('frac_pack.open_pack_button')"
        testId="btn-OpenPack"
        size="medium"
      />
    </div>
  </section>
</template>

<script>
import ButtonV2 from '@/stories/misc/ButtonV2';
import HoldingBaseAssetInformation from '@/modules/holdings/HoldingBaseAssetInformation';

export default {
  name: 'HoldingPackInformation',

  extends: HoldingBaseAssetInformation,

  components: {
    ButtonV2
  },

  props: {
    id: String,

    readableValues: {
      type: Object,
      required: true,
    },

    rawValues: {
      type: Object,
      required: true,
    },

    variation: {
      type: String,
      required: true,
    },
  },

  computed: {
    sectionClasses() {
      return {
        'resume-card': this.isCard,
        'resume-tile': this.isTile,
        'resume-tile--pack': this.variation === 'pack',
      };
    },

    currentPrice() {
      return this.readableValues.currentPrice
        || this.$t('holdings.packs.free');
    },

    showTileButton() {
      if (this.isTile) {
        return true;
      }

      return this.isMobileDevice;
    },

    showCardButton() {
      return !this.isTile && !this.isMobileDevice;
    },
  },

  methods: {
    openPack(data) {
      return this.showModal('FracPack', {
        pack: data,
        noPadding: true,
      });
    },
    
    packMethod() {
      return this.openPack({
        ...this.rawValues,
        variation: this.variation,
      });
    },
  }
};
</script>

<style scoped>
.resume-card {
  @apply grid gap-s16;
  grid-template-columns: 1fr 1fr;
}
  
.resume-tile {
  @apply grid gap-s64 pl-s16 items-center;
  grid-template-columns: repeat(2, 1fr);
}

.resume-tile--pack {
  @apply gap-s12;
  grid-template-columns: 1fr 1fr;
}
@media (min-width: 450px) {
  .resume-tile--pack {
    grid-template-columns: repeat(3, 1fr);
  }
}
</style>
