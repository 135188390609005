import { mapActions, mapGetters } from 'vuex';
import InfiniteScroll from '@/mixins/infinite-scroll';

export default {
  name: 'HoldingsMixin',

  mixins: [InfiniteScroll],

  data() {
    return {
      cardType: 'card',
      selectedTab: 'assets',
      sort: 'value_desc',
      isListLoading: false,
    };
  },

  computed: {
    ...mapGetters({ pagination: 'holdings/getPagination' }),

    showManualTrigger() {
      return !this.isListLoading
        && this.needManualTrigger
        && this.pagination.offset > 0
        && !this.pagination.endOfList;
    },
  },

  watch: {
    async scrollTop() {
      if (this.userScrolledOverTrigger) {
        await this.fetchOnScroll();
      }
    },

    pagination: {
      deep: true,

      handler: function (current) {
        if (current.endOfList) {
          return this.setInfiniteScrollStatus(false);
        }

        this.setInfiniteScrollStatus(true);
      },
    },

    selectedTab() {
      this.setInfiniteScrollStatus(true);
    },

    sort() {
      this.setInfiniteScrollStatus(true);
      this.resetState();
      this.fecthOnSort();
    }
  },

  methods: {
    ...mapActions('holdings', [
      'fetchHoldings',
      'resetState',
    ]),

    async fecthOnSort() {
      this.isListLoading = true;
      await this.fetchNewHoldings();
      this.isListLoading = false;
    },

    async fetchOnScroll() {
      this.setInfiniteScrollLoading(true);
      await this.fetchNewHoldings();
      this.setInfiniteScrollLoading(false);
    },

    async fetchNewHoldings() {
      await this.fetchHoldings({
        type: this.selectedTab,
        sort: this.sort,
      });
    }
  },

  mounted() {
    this.setInfiniteScrollStatus(true);
  },

  updated() {
    this.setInfiniteScrollTrigger();
  }
};
