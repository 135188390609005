<template>
  <section :class="sectionClasses">
    <HoldingItem 
      v-for="data in rawList"
      :key="data.id"
      v-bind="{ ...cardProps, data }"
    />
  </section>
</template>

<script>
import HoldingItem from '@/modules/holdings/HoldingItem/index.vue';

export default {
  name: 'HoldingList',

  components: {
    HoldingItem
  },

  props: {
    isLoading: Boolean,
    size: String,
    type: String,
    rawList: {
      type: Array,
      default: () => ([])
    } 
  },

  computed: {
    cardProps(){
      return {
        isLoading: this.isLoading,
        size: this.size,
        type: this.type
      };
    },
    
    sectionClasses(){
      return `list-${this.type}`;
    }
  }
};
</script>

<style scoped>
.list-card {
  @apply grid;
  grid-template-columns: 1fr;
  width: calc(100% + 40px);
  margin: 0 -20px;
}

.list-tile {
  @apply flex flex-col;
  margin: 0 -20px;
}

@screen start-lg {
  .list-card {
    @apply gap-s24 py-s24 w-full;
    grid-template-columns: repeat(2, 1fr);
    margin: auto;
  }

  .list-tile {
    margin: auto;
  }
}

@screen start-xl {
  .list-card {
    @apply w-full;
    grid-template-columns: repeat(3, 1fr);
  }
}
</style>
