<template>
  <div class="flex flex-row gap-s16 body-text-x-medium">
    <button type="button" @click="() => changeType('card')">
      <IconTilesBig :class="[isCard, buttonClasses]" />
    </button>
    <button type="button" @click="() => changeType('tile')">
      <IconTilesSmall :class="[isTile, buttonClasses]" />
    </button>
  </div>
</template>

<script>
import { IconTilesBig, IconTilesSmall } from '@/assets/icons';

export default {
  name: 'HoldingCardType',
  components: {
    IconTilesBig,
    IconTilesSmall
  },
  props: {
    value: {
      type: String,
      default: 'card'
    },
  },
  computed: {
    currentType: {
      get(){
        return this.value;
      },
      set(newValue){
        if (this.value !== newValue) {
         return this.$emit('input', newValue);
        }
      }
    },
    buttonClasses(){
      return 'fill-current hover:text-text-positive';
    },
    isCard(){
      return this.currentType === 'card' && 'text-text-positive';
    },
    isTile(){
      return this.currentType === 'tile' && 'text-text-positive';
    },
  },
  methods: {
    changeType(newValue){
      this.currentType = newValue;
    }
  }
};
</script>

<style scoped>
</style>
