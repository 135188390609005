<template>
  <LayoutMainPages>
    <div class="flex flex-col gap-s24">
      <HoldingsHeader />

      <HoldingsTabsAndFilters
        @onCardUpdate="setCardType"
        @onSortUpdate="setSort"
        @onListUpdate="setListLoader"
        @onTabUpdate="setTab"
        :isListLoading="isListLoading"
        :selectedTab="selectedTab"
        :cardType="cardType"
      />

      <HoldingsMain
        @onFetchAssets="fetchOnScroll"
        :cardType="cardType"
        :isListLoading="isListLoading"
        :selectedTab="selectedTab"
        :showManualTrigger="showManualTrigger"
        :isInfiniteScrollLoading="isInfiniteScrollLoading"
      /> 
    </div>
  </LayoutMainPages>
</template>

<script>
import HoldingsMixin from '@/modules/holdings/mixin';
import { LayoutMainPages }from '@/components/misc';

import {
  HoldingsHeader,
  HoldingsTabsAndFilters,
  HoldingsMain,
} from '@/modules/holdings';

export default {
  name: 'Holdings',

  mixins: [HoldingsMixin],

  components: {
    HoldingsHeader,
    HoldingsMain,
    HoldingsTabsAndFilters,
    LayoutMainPages,
  },

  methods: {
    setCardType(state) {
      this.cardType = state;
    },

    setSort(state) {
      this.sort = state;
    },

    setListLoader(state) {
      this.isListLoading = state;
    },

    setTab(state) {
      this.selectedTab = state;
    },
  },
};
</script>
