<template>
  <section :class="sectionClasses">
    <div class="flex flex-col">
      <BaseText v-bind="titleProps">
        {{ $t('holdings.assets.price_title') }}
      </BaseText>
      <BaseText size="subheadline-small">
        {{ readableValues.currentPrice }}
      </BaseText>
    </div>
    <div class="flex flex-col">
      <BaseText v-bind="titleProps">
        {{ $t('holdings.assets.holdings_title') }}
      </BaseText>
      <BaseText v-bind="valueProps">
        {{ readableValues.currentHolding }} / {{ readableValues.currentHoldingAmount }}
      </BaseText>
    </div>

    <PercentageBar
      v-if="isCard"
      class="asset-percentage"
      :percentage="rawValues.supplyPercent"
      :text="percentageText"
    />

    <div v-else class="flex flex-col">
      <BaseText v-bind="titleProps">
        {{ $t('holdings.drop.remaining') }}
      </BaseText>
      <BaseText v-bind="valueProps">
        {{ readableValues.supplyValue }} / {{ readableValues.supplyPercent }}
      </BaseText>
    </div>

  </section>
</template>

<script>
import {
  PercentageBar
} from '@/components/misc';

import HoldingBaseAssetInformation from '@/modules/holdings/HoldingBaseAssetInformation';

export default {
  name: 'HoldingDropInformation',
  extends: HoldingBaseAssetInformation,
  components: {
    PercentageBar
  },
  computed: {
    percentageText() {
      return this.rawValues.supplyPercent !== 100
          ? `${this.readableValues.supplyPercent} ${this.$t('drops.sold')}`
          : this.$t('drops.sold_out');
    },
    sectionClasses(){
      return {
        'asset-content': true,
        'asset-resume-card': this.isCard,
        'asset-resume-tile-large': !this.isSmall && this.isTile,
        'asset-resume-tile-small': this.isSmall && this.isTile,
      };
    }
  }
};
</script>

<style scoped>
  .asset-resume-card {
    @apply grid gap-s16;
    grid-template-columns: 1fr 1fr;
  }
   
  .asset-resume-tile-large {
    @apply grid gap-s64 pl-s16 items-center;
    grid-template-columns: repeat(4, 1fr);
  }

  .asset-resume-tile-small {
    @apply grid gap-s16 pl-s16;
    grid-template-columns: 1fr 1fr;
  }

  .asset-percentage { 
    grid-column: 1 / 3;
  }
</style>
