<template>

  <SkeletonLoading :class="tileType" class="asset-loading" v-if="isLoading" :hasCustomClass="true" />

  <router-link v-else :class="tileType" class="tile-grid bg-background-primary" :to="to">

    <SquareImage
      class="asset-image"
      :image="image"
    />

    <BaseText class="asset-title pl-s16" :size="titleSize">
      {{ title }}
    </BaseText>

    <slot />

  </router-link>

</template>

<script>
import BaseAsset from '../BaseAsset/index.js';

import { BaseText, SquareImage } from '@/components/misc';

export default {
  name: 'BaseAssetTile',
  extends: BaseAsset,
  props: {
    size: {
      type: String,
      default: 'small'
    }
  },
  components: {
    BaseText,
    SquareImage,
  },
  computed: {
    titleSize(){
      return {
        'small': 'body-text-medium',
        'large': 'subheadline-x-small',
      }[this.size]
    },
    tileType(){
      return `tile-${this.size}`;
    },
  }
}
</script>

<style scoped>
.asset-image {
  grid-area: image;
}

.asset-title {
  grid-area: title;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.asset-content {
  grid-area: content;
}

.asset-loading {
  width: 100%;
}

.tile-grid {
  @apply grid border-border border-b;
}

.tile-small.tile-grid {
  @apply p-s20 items-center;
  grid-template-areas: 'image title' 'image content';
  grid-template-columns: 40.55px 1fr;
}

.tile-large.tile-grid {
  @apply py-s32 px-s16;
  grid-template-areas: 'image title' 'image content';
  grid-template-columns: 100px 1fr;
}

.tile-grid:last-child {
  @apply border-0;
}

.tile-small.asset-loading {
  height: 113px;
}

.tile-large.asset-loading {
  height: 224px;
}

@screen start-lg {
  .tile-small.asset-loading {
    height: 64px;
  }

  .tile-small.tile-grid {
    @apply items-center p-s8;
    grid-template-areas: 'image title content';
    grid-template-columns: 30px 0.5fr 1fr;
  }

  .tile-small.asset-title {
    -webkit-line-clamp: initial;
    display: inline;
    white-space: nowrap;
  }
}
</style>
