<template>
  <main>
    <NoResult
      v-if="isListEmpty && !isListLoading"
      :title="emptyState.title"
      :message="emptyState.description"
      :btnLabel="emptyState.button"
      :btnLink="emptyState.link"
    />

    <HoldingsList
      v-else
      :size="cardSize"
      :type="cardType"
      :isLoading="isListLoading"
      :rawList="list"
    />
 
    <template>
      <aside
        v-if="showManualTrigger"
        class="flex items-center justify-center mt-s48"
      >
        <ButtonV2
          :label="$t('holdings.pagination.show_more')"
          testId="btn-show-more"
          version="secondary"
          size="medium"
          @onClick="fetchAssets"
        />
      </aside>

      <aside
        v-if="isInfiniteScrollLoading"
        class="mt-s48 relative"
      >
          <Loading smallLoading />
      </aside>
    </template>
  </main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ButtonV2, Loading, NoResult } from '@/components/misc';
import HoldingsList from '../HoldingsList';

export default {
  name: 'HoldingsMain',

  components: {
    ButtonV2,
    HoldingsList,
    Loading,
    NoResult,
  },

  props: {
    selectedTab: {
      type: String,
      required: true,
    },

    cardType: {
      type: String,
      required: true,
    },

    isListLoading: {
      type: Boolean,
      required: true,
    },

    isInfiniteScrollLoading: {
      type: Boolean,
      required: true,
    },

    showManualTrigger: {
      type: Boolean,
      required: true,
    }
  },

  computed: {
    ...mapGetters('holdings', [
      'getAssets',
      'getPacks',
      'getWatchlist',
      'getPagination',
    ]),

    cardSize() {
      return this.isMobileDevice ? 'small' : 'large';
    },

    emptyState() {
      const isPacks = this.selectedTab === 'packs';
      const description = isPacks 
        ? 'description_packs'
        : 'description';

      const button = isPacks
        ? 'button_view_rewards'
        : 'button_view_markets';

      const link = isPacks
        ? '/rewards'
        : '/market';

      return {
        title: this.$t(`holdings.empty_list.title_${ this.selectedTab}`),
        description: this.$t(`holdings.empty_list.${ description }`),
        button: this.$t(`holdings.empty_list.${ button }`),
        link,
      };
    },

    isListEmpty(){
      return !this.list.length
        && !this.isListLoading
        && !this.isInfiniteScrollLoading;
    },

    loadingList() {
      if (this.isListLoading || this.isInfiniteScrollLoading) {
        return new Array(this.getPagination.count)
          .fill({})
          .map((_item, key) => ({ 
            type: 'HoldingAsset',
            id: `loading-${key}`,
            isLoading: true
          }));
      }

      return [];
    },

    list() {
      let list = [];

      switch (this.selectedTab) {
        case 'assets':
          list = [ ...this.getAssets ];
          break;
        case 'packs':
          list = [ ...this.getPacks ];
          break;
        case 'watchlist':
          list = [ ...this.getWatchlist ];
          break;
      }

      this.updatePaginationOffset(list.length);
      
      return [
        ...list,
        ...this.loadingList
      ];
    },
  },

  methods: {
    ...mapActions({ updatePaginationOffset: 'holdings/updatePaginationOffset'}),

    fetchAssets() {
      this.$emit('onFetchAssets');
    }
  }
};
</script>
